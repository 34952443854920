import { pluralCount, titleize } from "@/lib/string"
import rpc from "@/src/rpc"
import { useRef, useState } from "react"
import RpcLoader from "./RpcLoader"
import SocialIcon from "./icons/legacy/SocialIcon"
import Toggle from "./Toggle"
import Modal from "./Modal"
import SocialConnectionPrep from "./SocialConnectionPrep"
import useHighlight from "@/src/useHighlight"
import DeleteIcon from "./icons/legacy/DeleteIcon"
import { sortBy } from "@/lib/array"
import Checkbox from "./Checkbox"
import LoadingSpinner from "./LoadingSpinner"
import withLoading from "@/src/withLoading"
import useCurrentOrg, { refreshCurrentOrg } from "@/src/useCurrentOrg"
import { connectTwitter, connectFacebook, connectInstagram, connectLinkedin } from "@/src/actions"


export default function SocialSettingsLoader () {
  const org = useCurrentOrg()

  if (!org) return <LoadingSpinner/>

  return <SocialSettings org={org}/>
}


export function SocialSettings ({org}) {

  const [connectingTo, setConnectingTo] = useState('')
  const [timePickerVisible, setTimePickerVisible] = useState(false)
  const [autoPublishHour, setAutoPublishHour] = useState(org.minPublishHour)
  const [updateExistingPosts, setUpdateExistingPosts] = useState(false)

  const autopublishRef = useRef(null)
  useHighlight('autopublish', autopublishRef)

  const connectRef = useRef(null)
  useHighlight('connect', connectRef)

  const accountsRef = useRef(null)
  useHighlight('accounts', accountsRef)

  const platformsAvailable = [
    'facebook',
    'instagram',
    'linkedin',
    'twitter',
  ]

  function showTimePicker(e) {
    e.preventDefault()

    setTimePickerVisible(true)
  }

  function cancelTimeChange () {
    setAutoPublishHour(org.minPublishHour)
    setTimePickerVisible(false)
  }

  async function updateAutoPublishTime() {
    withLoading(async () => {
      await rpc('orgs.setAutoPublishHour', {hour: autoPublishHour, updateExistingPosts})
      setTimePickerVisible(false)
    })
  }

  function hourToHumanTime(hour) {
    hour = parseInt(hour, 10)
    let result = hour
    if (hour === 0) result = 12
    if (hour > 12)  result = hour - 12
    const meridiem = hour >= 12 ? 'PM' : 'AM'
    return `${result}:00 ${meridiem}`
  }


  return <>
    <section className="settings">
      <RpcLoader method="social.status" render={(status, reload) => {
        const {destinations, allowExport, autoPublish, autoSchedule} = status
        const activeDestinations = sortBy(destinations.filter(d => d.targetId), dest => [
          dest.platform,
          dest.name,
          dest.id,
        ])


        async function toggleAutoPublish () {
          await rpc('social.setAutoPublish', {enabled: !autoPublish})
          await reload()
          await refreshCurrentOrg()
        }

        async function connect(platform) {
          setConnectingTo(platform)
        }

        async function disconnect(destination) {
          if (!confirm(`Are you sure you want to disconnect this account?`)) return
          await rpc('social.disconnect', {id: destination.id})
          await reload()
        }

        return <>

          <Modal visible={!!connectingTo} onClickOutside={() => setConnectingTo('')}>
            <SocialConnectionPrep platform={connectingTo} onClose={() => setConnectingTo('')}/>
          </Modal>

          <div ref={autopublishRef}>
            <AutoPublishControls
              enabled={autoPublish}
              exportAllowed={allowExport}
              autoSchedule={autoSchedule}
              onChange={toggleAutoPublish}
              connected={activeDestinations.length > 0}
            />
          </div>

          <div className="settings-group rounded flex-row spread mt-2">
            <span>
              Schedule posts for:
            </span>
            <a className="link" onClick={showTimePicker}>
              {hourToHumanTime(autoPublishHour)}
            </a>
          </div>

          <Modal visible={timePickerVisible} onClickOutside={cancelTimeChange}>
            <div className="flex-col gap-3 max-width-400 p-5">

              <h4 className="bold text-lg">
                Schedule posts for...
              </h4>

              <div className="select">
                <select className="full-width" defaultValue={autoPublishHour} onChange={e => setAutoPublishHour(e.target.value)}>
                  { [...Array(24).keys()].map(hour => (
                    <option key={hour} value={hour}>
                      {hourToHumanTime(hour)}
                    </option>
                  ))}
                </select>
              </div>

              <div className="my-1 flex-col gap-2">
                <p className="gray text-sm">
                  <em>Note:</em> This time will be used for any <b>new</b> posts.
                </p>
                <p className="gray text-sm">
                  If you want to update the time on <b>existing</b> (future)
                  posts, check the box below, and then tap Save.
                </p>
              </div>

              <Checkbox checked={updateExistingPosts} onChange={() => setUpdateExistingPosts(v => !v)}>
                <span>
                Reschedule existing posts <span className="sm_contents gray">(to {hourToHumanTime(autoPublishHour)})</span>
                </span>
              </Checkbox>

              <div className="flex-row flex-center gap-2 mt-3">
                <button className="button is-primary" onClick={updateAutoPublishTime} disabled={autoPublishHour == org.minPublishHour && !updateExistingPosts}>
                  &nbsp;Save&nbsp;
                </button>
                <button className="button" onClick={cancelTimeChange}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          <h4 className="mx-1">Social Media Accounts</h4>

          <div ref={accountsRef}>
            { activeDestinations.map(destination => (
              <SocialPlatformControls
                key         = {destination.id}
                destination = {destination}
                disconnect  = {disconnect}
                reload      = {reload}
                org         = {org}
              />
            ))}
          </div>

          { activeDestinations.length > 0 && <div className="mt-5 mb-1 gray mx-1">Want to add another account?</div>}

          <div className="flex-col flex-stretch" ref={connectRef}>
            { platformsAvailable.map(platform => (
              <button className={`connect button ${platform}`} onClick={() => connect(platform)} key={platform}>
                <SocialIcon platform={platform}/>&nbsp; Connect&nbsp;{platform === 'twitter' ? <span>X <span className="not-bold opacity-40">({titleize(platform)})</span></span> : titleize(platform)}
              </button>
            )) }
          </div>
        </>
      }} />

    </section>

    <hr/>

    <style jsx>{`
      section {
        margin: 1rem 0;
        padding: 0 1rem;
        padding-bottom: 12rem;
      }
      .connect.button {
        width: 100%;
        border-radius: 4px !important;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
        margin: 3px;
        font-weight: bold;
        justify-content: flex-start;
      }
      .connect.button:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) !important;
      }
      .facebook.connect.button {
        border-color: #1877F2;
        background-color: #1877F211;
        color:#1877F2;
      }
      .instagram.connect.button {
        border-color: #E23523;
        background-color: #E2352311;
        color:#E23523;
      }
      .linkedin.connect.button {
        border-color: #0A66C2;
        background-color: #0A66C211;
        color:#0A66C2;
      }
      .twitter.connect.button {
        border-color: #00000066;
        background-color: #00000011;
        color:#000;
      }
    `}</style>
  </>
}


function AutoPublishControls ({enabled, onChange, autoSchedule, exportAllowed, connected}) {

  return <>
    <div className="settings-group rounded">
      <div className="spread">
        <label>
          Auto-Publish
        </label>
        <Toggle checked={enabled} onChange={onChange}/>
      </div>

      { enabled ?
        <p className="details">
          {autoSchedule ?
            'Your posts'
          :
            'After you pick a time for a post, it'
          } will be automatically published to your social media
          accounts {connected ?
            null
          :
            <span className="bold">after you connect them</span>
          } (below).
        </p>
      :
        <>
          <p className="details orange">
            <b>Publishing is off.</b> Each day, you will need to manually publish { exportAllowed &&
            <>(or <b>Export</b>)</> } each of your posts.
          </p>
        </>
      }
    </div>
  </>
}


function SocialPlatformControls ({destination, disconnect, org}) {

  const {platform, name, url, daysUntilDisconnect} = destination

  const disconnected = daysUntilDisconnect <= 0
  const expiresSoon = daysUntilDisconnect < 14 // days before disconnect

  const showReconnectUI = disconnected || expiresSoon
  const showMetricsUnavailable = (org?.analyticsEnabled || org?.metricsEnabled) && !destination.canAccessMetrics

  const showUsername = ['instagram', 'twitter'].includes(platform)

  async function reconnect(destination) {
    if (destination.platform === 'facebook')  return connectFacebook()
    if (destination.platform === 'linkedin')  return connectLinkedin()
    if (destination.platform === 'twitter')   return connectTwitter()
    if (destination.platform === 'instagram') return connectInstagram()
  }

  return <>
    <div className={`border-gray b-1 py-1 pl-3 pr-1 my-2 rounded ${platform}`}>
      <div className="flex-row spread relative flex-align-start">
        <div className="flex-row flex-align-start gap-2 relative overflow-hidden" style={{paddingTop: '0.3rem', paddingBottom: '0.3rem'}}>
          <div className="flex-no-shrink">
            <SocialIcon platform={platform}/>
          </div>
          <a href={url} target="_blank" rel="noreferrer" className="dark-gray link block flex-row overflow-hidden flex-grow">
            <span className="small discourage-wrap full-width">
              { !!showUsername
                ? `@${name}`
                : name
              }
            </span>
          </a>
        </div>
        <button className="button icon-button is-inverted opacity-30 transition hover_opacity-100" onClick={() => disconnect(destination)}>
          <DeleteIcon/>
        </button>
      </div>
      { showReconnectUI ?
        <div>
          <div className="my-1 mr-2 text-sm">
            { disconnected ?
              <span className="danger">
                <b>Error:</b> This connection has expired, so your posts are NOT getting published to this account. Please reconnect to fix this.
              </span>
            :
              <span className="orange">
                <b>Warning:</b> This connection will expire in <span className="no-wrap">{ pluralCount(daysUntilDisconnect, 'day')}</span>. If you do not reconnect before then, your posts will stop be published.
              </span>
            }
          </div>
          <div className="text-center">
            <button className="button is-primary my-2" onClick={() => reconnect(destination)}>
              Reconnect
            </button>
          </div>
        </div>
      : showMetricsUnavailable ?
        <div>
          <div className="my-1 mr-2 text-sm">
            <span className="gray">
              To get access to performance metrics, you need to reconnect this account.
            </span>
            <div className="text-center">
              <button className="button is-primary my-2" onClick={() => reconnect(destination)}>
                Reconnect
              </button>
            </div>
          </div>
        </div>
      :
        null
      }
    </div>

    <style jsx>{`
      .discourage-wrap {
        display: inline-block;
      }
      .small.button {
        font-size: 0.9rem;
      }
    `}</style>
  </>

}